<template>
  <b-row class="match-height">
    <b-col
lg="8"
offset="2"
> <b-card
    title="Receive Transfer By Receiver"
  >
    <b-form
        @submit.prevent="submitConfirmTransfer"
>
      <b-row>

        <b-col cols="12">
          <b-form-group
                label="Items *"
                label-for="chosenItem"
              >
                <v-select-new
                  v-model="chosenItem"
                  :filterable="true"
                  :options="items"
                  @input="putItem"
                />
              </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              QTY Accepted
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              QTY Received
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Shelf
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Actions
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(chosen) in chosens"
:key="chosen.value"
>
          <br>
            <b-col cols="4 text-center">
                <b-list-group>
                  <b-list-group-item href="#">
{{ chosen.label }}
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col cols="2 text-center">
            <b-form-input
              v-model="chosen.accepted"
              type="number"
              :max="chosen.accepted"
              readonly
            />
            </b-col>
            <b-col cols="2 text-center">
            <b-form-input
              v-model="chosen.received"
              type="number"
              required="required"
              :max="chosen.accepted"
            />
            </b-col>
            <b-col cols="2 text-center">
              <v-select-new
                    v-model="chosen.shelf"
                    :options="shelfs"
                  />
            </b-col>
            <b-col cols="2 text-center">
            <b-button
              size="sm"
              type="button"
              variant="danger"
              class="mr-1"
              @click="removeRow(chosen.id)"
            >
                  <feather-icon
                    icon="TrashIcon"
                    size="18"
                  />
            </b-button>
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
        </b-col>
        <!-- submit and reset -->
        <b-col offset-md="12">
          <b-button
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker, BFormFile,
  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

const VersionCheck = ''
const file = ''
const items = []
const chosenPR = ref()
const chosens = []
const shipmentTo = ''
const itemId = ''
const purchaseOrderProject = ''
const purchaseOrderCostCenter = ''
const purchaseOrderQtyRequested = 0
const purchaseOrderStatus = ''
const purchaseOrderNotes = ''
const deliverySchedule = ''
const chosenItem = ''
const modalReferenceShow = false
const itemReference = ''
const itemLink = ''
const itemPrice = ''
const itemDescription = ''
const contacts = []
const branches = []
const references = []
const currencies = []
const returns = []
const chosenBranch = ''
const chosenContact = ''
const termOfPayment = ''
const billingAddress = ''
const deliveryAddress = ''
const purchaseOrderDetailId = ''
const modalReturnShow = false
const itemCurrency = ''
const returnItemQty = ''
const chosenShelf = ''
const shelfs = []
const modalReferenceHistoryShow = false

export default {
  components: {
    BFormFile,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      chosenItem,
      shelfs,
      chosenShelf,
      returns,
      returnItemQty,
      purchaseOrderDetailId,
      modalReturnShow,
      itemCurrency,
      currencies,
      deliveryAddress,
      chosenBranch,
      branches,
      termOfPayment,
      billingAddress,
      modalReferenceHistoryShow,
      chosenContact,
      contacts,
      itemId,
      deliverySchedule,
      file,
      modalReferenceShow,
      itemReference,
      itemLink,
      itemPrice,
      itemDescription,
      references,
    VersionCheck,
    items,
    chosenPR,
    chosens,
    shipmentTo,
    purchaseOrderProject,
    purchaseOrderCostCenter,
    purchaseOrderQtyRequested,
    purchaseOrderStatus,
    purchaseOrderNotes,
    }
  },
  mounted() {
    const shelfsVariables = process.env.VUE_APP_SHELF_LIST.split(",")
    const shelfsRepeat = process.env.VUE_APP_SHELF_REPEAT.split(",")
    this.shelfs = []
    shelfsVariables.map(s => {
      shelfsRepeat.map(r => {
        this.shelfs.push(`${s}${r}`)
      })
    })
      this.getDetails()
  },
  methods: {
  getDetails() {
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    const item = this.$cookies.get('TransferDetail')
    const id = item.transfer_id
    axios
    .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_TRANSFER_DETAILS}${id}`, { headers })
    .then(response => {
      if (response.data.success === true) {
          const temp = []
          response.data.data.map(elem => {
            temp.push({
              label: elem.item.item_name, value: elem.item.item_id, id: elem.transfer_detail_id, qty: elem.transfer_detail_item_qty_requested, accepted: elem.transfer_detail_item_qty_accepted, received: elem.transfer_detail_item_qty_accepted, shelf: '',
            })
          })
        this.items = temp
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Data success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
      } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Fetching Data failed',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
      }
      })
  },
  submitConfirmTransfer() {
    const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
    const item = this.$cookies.get('TransferDetail')
    const id = item.transfer_id
    const body = {
      transfer_items: JSON.stringify(this.chosens),
      time: moment(),
    }
    const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_RECEIVE_TRANSFER}${id}`
    axios
      .post(url, body, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.chosens = []
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Transfer Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.replace({ name: 'apps-transfers-list' })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Confirm Transfer Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Confirm Transfer Failed, Something Went Wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
    getOptionLabel(option) {
      return (option && option.label) || ''
    },
    inputSearch(search) {
      console.log(search)
    },
    handleFilesUpload(e) {
        this.file = e.target.files || e.dataTransfer.files
    },
    addReference(val) {
      this.modalReferenceShow = true
      this.itemId = val
    },
    showReference(val) {
      const userToken = this.$cookies.get('userToken')
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${userToken}`,
}
      this.itemId = val
      this.modalReferenceHistoryShow = true
    axios
      .get(`${process.env.VUE_APP_API_GET_ITEM_LOOKUP}${val}`, { headers })
      .then(response => {
        if (response.data.success === true) {
              this.references = response.data.data
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get References Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
              title: 'Get References Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    dateSimple(value, format = 'MM/DD/YYYY') {
        return moment(String(value)).format(format)
    },
    returnItem(id) {
      this.purchaseOrderDetailId = id
      this.modalReturnShow = true
    },
    saveReturnItem() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Return Item Saved',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    shelfChange(id) {
      this.chosens.map(o => {
        if (o.value === id) {
          o.shelf = this.chosenShelf
        }
      })
    },
    choose(referenceId, itemIdRef) {
      const index = this.chosens.findIndex(x => x.value === itemIdRef)
      this.chosens[index].reference_id = referenceId
      this.modalReferenceHistoryShow = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Price Chosen',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
      putItem(val) {
        if (this.chosens.length > 0) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    label: elem.label, value: elem.value, qty: elem.qty, accepted: elem.accepted, received: elem.accepted, id: this.chosens.length + 1, transfer_detail_id: elem.id,
                  }
                }
              })
            this.chosens.push(item)
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    label: elem.label, value: elem.value, qty: elem.qty, accepted: elem.accepted, received: elem.accepted, id: this.chosens.length, transfer_detail_id: elem.id,
                  }
                }
              })

            this.chosens.push(item)
        }
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.id !== val))
      },
  },
}
</script>